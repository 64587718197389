.lng-reference {
    margin-bottom: 90px;

    @include breakpoint($size-tablet){
        margin-bottom: 30px;
    }

    & > div {
        margin-bottom: 65px;
        padding: 20px 0 0 186px;
        position: relative;

        @include breakpoint($size-tablet){
            padding: 110px 0 0;
        }

        .icon {
            width: 155px;
            height: 80px;
            position: absolute;
            top: 20px;
            left: 0;
            border: 1px solid $color-grey-med;
            border-radius: 8px;
            background-position: 50%;

            @include breakpoint($size-tablet){
                top: 10px;
                left: 50%;
                margin-left: -77px;
            }
        }
    }
}