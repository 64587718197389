$slide-time: 0.5s;

body > header {
    position: relative;
    z-index: 5;
    @extend %inverse;
    @extend %clearfix;

    .notification {
        height: 44px;
        padding-top: 10px;
        background: $color-black-dark;
        text-align: center;

        a {
            color: $color-blue;
            text-decoration: none;

            span {
                margin-right: 10px;
                padding: 4px 10px;
                background: $color-black;
                border-radius: 3px;
                text-transform: uppercase;
            }
        }
    }

    .page-wrap {
        @extend %clearfix;

        & > div {
            @extend %clearfix;

            .logo {
                width: 135px;
                height: 58px;
                margin-top: 6px;
                display: inline-block;
                text-indent: 100%;
                overflow: hidden;
                white-space: nowrap;
                float: left;
                opacity: 1;
            }

            .hamburger {
                display: none;
                float: right;
                margin-top: 12px;

                a {
                    margin-right: 15px;

                    &:last-child {
                        margin: 0;
                    }

                    @include breakpoint($size-mobile-small) {
                        margin-right: 10px;
                    }

                    &:not(.btn) {
                        line-height: rem(40px);
                    }
                }

                @include breakpoint($size-tablet-small) {
                    display: inline-block;
                }
            }
        }
    }

    nav a {
       margin-right: 30px;
    }

    a {
        text-decoration: none;
        opacity: 0.5;
        font-weight: 600;

        &:hover {
            opacity: 0.85;
        }

        &:last-child {
            margin: 0;
        }

        &.btn {
            opacity: 1;
        }
    }

    .header__primary {
        padding: 25px 0;
        @include linear-gradient($gradient-tilt-header, $color-gradient-red, $color-gradient-orange);

        .page-wrap {
            & > div {
                float: left;

                @include breakpoint($size-tablet-small){
                    float: none;
                }
            }
        }

        nav {
            float: right;
            transition: height $slide-time, margin $slide-time;
            margin-top: 12px;

            @include breakpoint($size-tablet-small) {
                width: 100%;
                height: 0;
                padding: 0;
                float: none;
                overflow: hidden;
                background: rgba(0, 0, 0, 0.25);
            }

            a {
                @include breakpoint($size-tablet-small) {
                    margin: 0;
                    padding: 15px 0;
                    display: block;
                    text-align: center;
                }

                &.btn {
                    @include breakpoint($size-tablet-small) {
                        display: none;
                    }
                }
            }
        }
    }

    .header__secondary {
        height: 51px;
        padding: 15px 0 15px;
        position: relative;

        @extend %clearfix;

        & > div {
            height: 51px;
            width: 100%;
            padding: 15px 0 15px;
            border-bottom: 1px solid $color-grey-med;
            background: $color-white;
            top: 0;
            position: absolute;
        }

        a {
            padding-bottom: 13px;
            color: $color-grey-dark;
            opacity: 1;
            position: relative;

            &:hover {
                text-decoration: none;
                color: $color-blue;
            }

            &.active {
                color: $color-blue;
                border-bottom: 3px solid $color-blue;
            }


            @include breakpoint($size-tablet-small){
                margin-right: 10px;
                padding-right: 15px;

                &:last-of-type {
                    margin: 0;
                }

                &:before {
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 9px;
                    right: 0;
                    @include arrow(4px, $color-grey-dark, "down");
                    content:"";
                }

                &:after {
                    width: 0;
                    height: 0;
                    position: absolute;
                    top: 8px;
                    right: 0;
                    @include arrow(4px, $color-white, "down");
                    content:"";
                }

                &.active {
                    &:before {
                        @include arrow(4px, $color-blue, "down");
                    }
                }

                &.no-nav {
                    padding-right: 0;
                    &:after, &:before {
                        border: none;
                    }
                }
            }

            @include breakpoint($size-mobile-small) {
                padding-right: 12px;
            }
        }

        .custom-select {
            display: inline-block;
            //a little hacky but fixes alignment issues on iOS
            position: absolute;
            top: -5px;

            @include breakpoint($size-tablet-small){
                display: none;
            }

            select, option {
                text-transform: uppercase;
            }
        }
    }
}

body.home > header .header__secondary {
    border-bottom: none;

    @include breakpoint($size-tablet-small){
        background: $color-white;
    }

    & > div {
        background: rgba($color-white, 0.5);
    }

    a {
        color: $color-white;

        @include breakpoint($size-tablet-small){
            color: $color-grey-dark;
        }
    }
}
