//Add this to head: <link href='http://fonts.googleapis.com/css?family=Roboto+Slab:300|Open+Sans:400,600,700' rel='stylesheet' type='text/css'>
// 300 = light, 400 = normal, 600 = semi-bold, 700 = bold

body {
    font-family: "Open Sans", sans-serif;
    color: $color-black;
    line-height: rem(21px);
    -webkit-font-smoothing: antialiased;
}

html {
    font-size: $base-font-size;
}

input, select, textarea, button{
    font-family:inherit;
    font-size: inherit;
    line-height: inherit;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 12px 0;
    font-weight: 600;
    color: $color-orange;
}

h1 {
    @include font-size-h1;
    font-weight: 300;
    color: $color-orange;
    @extend %roboto-slab;

    @include breakpoint($size-tablet){
        font-size: rem(27px);
        line-height: rem(32px);
    }
}

h2 {
    @include font-size-h2;
    font-weight: 400;
}

h3 {
    @include font-size-h3;
}

h4 {
    @include font-size-h4;
}

h5 {
    @include font-size-h5;
}

h6 {
    @include font-size-h5;
    text-transform: uppercase;
}

p {
    @include font-size-paragraph;
    margin: 0 0 25px 0;
    padding: 0;
}

a {
    color: $color-blue;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    %inverse & {
        color: $color-white;
        text-decoration: underline;
        cursor: pointer;
    }
}

strong {
    font-weight: 700;
}

em {
    font-style: italic;
}

blockquote {
    margin-bottom: 12px;
    padding-left: 15px;
    border-left: 2px solid $color-orange;
    font-size: rem(15px);
    line-height: rem(25px);
    font-style: italic;
    color: $color-grey-med-3;
}

hr {
    height: 1px;
    margin: 35px 0;
    background-color: $color-grey-med;
    border: 0;
}

ul {

    li {
        font-size: rem(16px);
    }

    &.bullet {
        margin-bottom: 30px;

        li {
            margin: 0 0 5px 15px;
            position: relative;
            &:before {
                position: absolute;
                width: 4px;
                height: 4px;
                top: 9px;
                left: -12px;
                border-radius: 50%;
                background-color: $color-black;
                content: "";
            }

            li {
                margin-left: 40px;
                &:before {
                    width: 5px;
                    height: 5px;
                    top: 8px;
                    border: solid 1px $color-black;
                    background-color: transparent;
                }
            }
        }
    }
}

ol {
    margin: 0 0 20px 20px;
    list-style-type: decimal;

    li {
        margin-bottom: 3px;
        padding-left: 3px;
        font-size: rem(16px);
    }
}

code {
    padding: 2px 5px;
    background: $color-grey-3;
    border-radius: 3px;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-weight: 400;
    font-style: normal;
}

pre {
    margin-bottom: 40px;
    padding: 14px 0 14px 14px;
    overflow: auto;
    code {
        padding: 0;
        background: transparent;
    }
}

// This needs dwolla.styletiles.responsiveTable js to work.
table {
    margin-bottom: 30px;

    thead {
        @include breakpoint($size-mobile){
            display: none;
        }

        th {
            padding: 6px 15px;
            font-size: rem(15px);
            font-weight: 700;
            text-align: left;
        }
    }

    tbody {
        border-top: 1px solid $color-grey-3;
        border-bottom: 1px solid $color-grey-3;
        background: $color-grey-light;

        tr {
            @include breakpoint($size-mobile){
                border: 1px solid #ccc;
            }

           &:nth-child(even) {
                background: $color-grey;
            }

            td {
                padding: 6px 15px;
                font-size: rem(13px);

                @include breakpoint($size-mobile){
                    width: 100%;
                    padding-left: 50%;
                    display: block;
                    position: relative;

                    &:first-child {
                        padding-top: 20px;
                        &:before {
                            top: 20px;
                        }
                    }

                    &:last-child {
                        padding-bottom: 20px;
                    }
                }

                &:before {
                    width: 48%;
                    display: none;
                    position: absolute;
                    top: 6px;
                    left: 15px;

                    @include breakpoint($size-mobile){
                        display: block;
                    }
                }
            }
        }
    }
}
