.toggle-buttons {
    margin-bottom: 30px;
    @extend %clearfix;

    a {
        width: 50%;
        padding: 7px 28px;
        float: left;
        border: 1px solid $color-grey-med;
        background: $color-grey-light;
        text-align: center;
        color: $color-grey-dark;

        @include breakpoint($size-mobile){
            width: 100%;
        }

        &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            @include breakpoint($size-mobile){
                border-top-right-radius: 5px;
                border-bottom-left-radius: 0;
            }
        }

        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            @include breakpoint($size-mobile){
                border-top-right-radius: 0;
                border-bottom-left-radius: 5px;
            }
        }

        &:hover {
            background: $color-white;
            text-decoration: none;
        }

        &.active {
            color: $color-white;
            background: $color-blue;
            border: 1px solid $color-blue;
        }
    }
}