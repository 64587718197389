.pager-nav {
    margin: 40px 0;
    font-weight: 600;
    @extend %clearfix;

    @include breakpoint($size-mobile-small){
        font-size: rem(13px);
    }

    a {
        &:first-child {
            @include breakpoint($size-tablet-small){
                display: none;
            }

            &:before {
                content: "< ";
            }
        }
        &:last-child {
            float: right;
            &:after {
                content: " >";
            }
        }
    }
}