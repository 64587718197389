.steps-nav {
    padding: 30px 0;
    font-weight: 600;

    a {
        display: block;
        color: $color-grey-dark;
        line-height: rem(16px);

        &:hover {
            text-decoration: none;
            color: $color-blue;
        }
    }

    h6 {
        margin-bottom: 5px;
        padding-left: 30px;
        color: $color-black;
        font-size: rem(12px);
        letter-spacing: 1px;
    }

    ol {
        margin: 0;
        padding: 0;
        font-size: rem(12px);
        list-style-type: none;

        li {
            margin: 0;
            padding-left: 0;
            font-size: rem(12px);
            position: relative;
            
            a {
                padding: 9px 10px 9px 55px;
                position: relative;
                z-index: 1;

                &.active {
                    color: $color-blue;
                    background-color: $color-grey-2;
                }

                &:before {
                    height: 100%;
                    content: "";
                    position: absolute;
                    border-right: 1px solid $color-grey-med;
                    top: 0;
                    left: 34px;
                }

                &:hover {
                    color: $color-blue;
                    background-color: $color-grey-2;
                    text-decoration: none;
                }
            }

            .active + .dot {
                background-color: $color-blue;
                &:after {
                    background: transparent;
                }
            }

            .dot {
                width: 10px;
                height: 10px;
                position: absolute;
                top: 12px;
                left: 30px;
                z-index: 2;
                border-radius: 50%;
                background-color: $color-grey-med-2;

                &:after {
                    width: 6px;
                    height: 6px;
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    border-radius: 50%;
                    background-color: $color-grey;
                }
            }

            ol {

                li {
                    .active + .dot {
                        &:after {
                            background-color: $color-grey;
                        }
                    }

                    a {
                        padding-left: 75px;

                        &:after {
                            width: 25px;
                            content: "";
                            position: absolute;
                            border-bottom: 1px solid $color-grey-med;
                            top: 17px;
                            left: 34px;
                        }
                    }

                    .dot {
                        left: 50px;
                    }
                }
            }
        }
    }

    & > ol > li {
        //top and bottom borders
        &:first-child {
            & > a:before {
                height: 50%;
                top: auto;
                bottom: 0;
            }
        }

        &:last-child {
            & > a:before {
                height: 50%;
            }
        }
    }
}

.steps-nav-interior {
    padding: 30px;
    background: $color-grey;
    font-weight: 600;

    a {
        display: block;
        color: $color-grey-dark;
        line-height: rem(16px);

        &:hover {
            text-decoration: none;
            color: $color-blue;
        }
    }

    h6 {
        margin-bottom: 5px;
        color: $color-grey-dark;
        font-size: rem(12px);
        letter-spacing: 0.5px;
    }

    section {
        border-bottom: 1px solid $color-grey-med;

        &:last-child {
            border: none;
        }
    }

    & > div {
        background: $color-white;
        border: 1px solid $color-grey-med;
        border-radius: 5px;

        section {
            background: $color-white;
        }

        & > a {
            padding: 12px 15px;
            color: $color-blue;
            font-weight: 600;
            text-align: center;
            border-bottom: 1px solid $color-grey-med;

            &.active {
                background: $color-blue;
                color: $color-white;
            }
        }
    }

    ol {
        margin: 0;
        padding: 10px 15px 10px 30px;
        font-size: rem(12px);
        list-style-type: decimal;

        li {
            margin-bottom: 5px;
            padding-left: 5px;
            font-size: rem(12px);
            
            a {
                padding: 8px 10px;
                background: $color-grey;
                border-radius: 5px;

                &:hover {
                    color: $color-blue;
                    text-decoration: none;
                }

                &.active {
                    color: $color-white;
                    background: $color-blue;
                }
            }
        }

        .twofer {
            //explicit height as list-type is removed when clearfix is applied.
            height: 70px;
            padding-bottom: 8px;
            position: relative;
            

            span {
                margin-bottom: 8px;
                display: block;
            }

            a {
                width: 43%;
                float: left;
                text-align: center;

                &:nth-child(2):after {
                    width: 50%;
                    height: 20px;
                    margin: -8px 0 0 10px;
                    top: 65%;
                    left: 0;
                    position: absolute;
                    content: "or";
                    text-transform: uppercase;
                    color: $color-grey-dark;
                    text-align: right;
                }

                &:last-child {
                    padding-left: 15px;
                    float: right;
                }
            }
        }
    }
}