.split-options {
    text-align: center;
    @extend %clearfix;


    & > div {
        width: 50%;
        padding: 75px 0;
        float: left;

        @include breakpoint($size-tablet-small){
            width: auto;
            padding: 30px 0 10px;
            float: none;
        }

        h3 {
            padding-top: 50px;
            background-position: 50% 0;
        }

        .btn {
            min-width: 300px;
        }
    }
}
