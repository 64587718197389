.icon-links {
    margin: 30px 0;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;

    @extend %clearfix;

    li {
        width: 30%;
        margin-right: 2.5%;
        margin-bottom: 30px;
        display:flex;
        display: -webkit-flex;
        display: -ms-flexbox;

        &:nth-child(2n) {
            @include breakpoint($size-desktop-small){
                margin-right: 0;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        @include breakpoint($size-desktop-small){
            width: 49%;
            margin-right: 2%;
        }

        @include breakpoint($size-mobile){
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
        }

        a {
            width: 100%;
            padding: 20px 10px 20px 70px;
            display: block;
            background-position: 10px 50%;
            border: 1px solid $color-grey-med;
            border-radius: 6px;
            transition: all 0.5s ease 0s;
            justify-content: center;
            font-weight: 600;

            &:hover {
                border-color: $color-blue;
                box-shadow: 0 0 5px rgba(44, 44, 44, 0.25);
                text-decoration: none;
            }

            i.material-icons {
                margin-left: -60px;
                margin-right: 36px;
            }
        }
    }
}