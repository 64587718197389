//box size all the things!
*, *:before, *:after { box-sizing: border-box; }

/* ------ RESETS ------- */
button::-moz-focus-inner {
    padding: 0;
    border: 0
}

//for use in js
body {
    &:before{
        content:"desktop"; display: none;
        @include breakpoint($size-tablet) { content:"tablet"; }
        @include breakpoint($size-tablet-small) { content:"tablet_small"; }
        @include breakpoint($size-mobile) { content:"mobile"; }
        @include breakpoint-landscape($size-mobile) { content:"mobile-landscape"; }
    }
}