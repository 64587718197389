.article-list {
        margin: 30px 0;
        display: flex;
        display: -webkit-flex;
        display: -ms-flexbox;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;

        li {
            width: 30%;
            margin: 0 4% 0 0;

            @include breakpoint($size-desktop-small){
                width: 48%;

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }

            @include breakpoint($size-mobile){
                width: 100%;
                margin: 0 0 10px 0;
            }

            &:nth-child(3n) {
                margin-right: 0;

                @include breakpoint($size-desktop-small){
                    margin-right: 4%;

                    @include breakpoint($size-mobile){
                        margin-right: 0;
                    }
                }
            }
        }
}