.banner {
    padding: 68px 0 72px 0;
    position: relative;
    text-align: center;

    h1 {
        max-width: 865px;
        margin: 0 auto 35px;
        color: $color-white;
        font-size: rem(40px);
        line-height: rem(46px);

        &.icon {
            padding-top: 100px;
            background-position: 50% 0;
        }
    }

    &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        background: image-url("../images/banner_developer_desktop.jpg") center center no-repeat;
        background-size: cover;

        @include breakpoint($size-tablet){
            background: image-url("../images/banner_developer_tablet.jpg") center center no-repeat;
            background-size: cover;
        }

        @include breakpoint($size-tablet-small){
            background: $color-red;
        }

        &:after {
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            @include linear-gradient($gradient-tilt-banner, rgba($color-gradient-red, 0.95), rgba($color-black, 0.5));

            @include breakpoint($size-mobile){
                display: none;
            }
        }
    }
}
