.copyright {
    padding-bottom: 30px;
    position: relative;
    z-index: 5;
    color: $color-grey-med-3;
    background: $color-white;

    .page-wrap {

        & > div {
            padding-top: 30px;
        }
    }

    a {
        color: $color-grey-med-3;
        text-decoration: underline;

        @include breakpoint($size-mobile){
            margin-top: 25px;
            display: block;
        }
    }

    span {
        padding-right: 45px;
    }
}

.cnt-two-col__content > .copyright {
    .page-wrap {
        padding: 0;
    }
}
