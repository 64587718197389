@import "developer";
@import "base";

.code-snippet {
  code {
    // color: #f8f8f2;
  }
}

banner {
  .top-image {
    margin-bottom: 10px;
  }
}

.signupForm {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;

  dl {
    display: block;
    width: 100%;
    margin-bottom: 1.5em;
    border: 1px solid #d8e0e5;
    border-radius: 3px;

    //padding: 2em;

    label {
      position: relative;
      top: 0;
      left: 0;
      color: black;
      //padding-right: 2em;
    }
    dt {
      display: inline-block;
      width: 100%;
      //text-align: right;
    }

    dd {
      display: inline-block;
      width: 100%;
      input {
        width: 100%;
        text-align: center;
        border: 0;
        margin-bottom: 0;
        padding: 0.5em 1em;
      }
    }

    dd.info {
      display: none;
    }

    dd.error {
      background-color: #d8e0e5;
    }
    clear: both;
  }

}