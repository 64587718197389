.decision-nav {
    max-width: 927px;
    text-align: center;
    @extend %clearfix;

    & > div {
        width: 45%;
        margin-bottom: 30px;
        float: left;

        @include breakpoint($size-tablet-small){
            width: 100%;
            max-width: 400px;
            margin: 0 auto 30px;
            float: none;
        }

        &:nth-child(odd) {
            margin-right: 10%;

            @include breakpoint($size-tablet-small){
                margin-right: auto;
            }
        }

        &:first-child {
            position: relative;

            &:after {
                width: 28px;
                height: 28px;
                margin: -35px -15px 0 0;
                padding-top: 2px;
                content: "OR";
                position: absolute;
                right: -10%;
                top: 50%;
                font-size: rem(12px);
                border: 1px solid $color-grey-med;
                border-radius: 50%;
                color: $color-grey-dark;
                background: $color-white;

                @include breakpoint($size-tablet-small){
                    display: none;
                }
            }

            &:before {
                height: 100px;
                margin-top: -70px;
                content: "";
                position: absolute;
                right: -10.5%;
                top: 50%;
                border-right: 1px solid $color-grey-med;

                @include breakpoint($size-tablet-small){
                    display: none;
                }
            }
        }
    }

    a {
        margin-bottom: 15px;
        display: block;
        font-size: rem(20px);

        &:hover {
            text-decoration: none;

            div {
                border-color: $color-blue;
            }
        }

        div {
            width: 100%;
            margin-bottom: 15px;
            padding-top: 68%;
            border: 1px solid $color-grey-med;
            border-radius: 8px;
            background-size: 65%;
            background-position: center;
        }
    }

    p {
        margin: 0;
    }

    &__decision {
        

        div {

        }
    }
}