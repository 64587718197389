.change-log {
    margin-bottom: 60px;

    h1 {
        margin: 70px 0 20px;

        &:first-child {
            margin-top: 0;
        }
    }

    h3 {
        margin-bottom: 0;
    }

    h4 {
        color: $color-grey-dark;
    }

    ul {
        margin-left: 15px;
        @extend .bullet;
    }
}