body > footer, .cnt-two-col__content > footer {
    padding-top: 50px;
    position: relative;
    z-index: 5;
    background: $color-white;

    h6 {
        font-size: rem(14px);
        font-weight: 400;
        color: $color-grey-med-3;
    }

    .page-wrap {
        @include flexbox();
        @include flex-wrap(wrap);

        @include breakpoint($size-mobile){
            padding: 0 30px;
        }

        @include breakpoint($size-mobile-small){
            padding: 0;
        }

        & > nav {
            margin-bottom: 55px;
            @include flex-grow(1);

            @include breakpoint($size-mobile){
                width: 50%;
            }

            @include breakpoint($size-mobile-small){
                width: 100%;
                text-align: center;
            }

            a {
                margin-bottom: 6px;
                display: block;
            }
        }
    }
}

.cnt-two-col__content > footer {
    padding-top: 15px;

    .page-wrap {
        padding: 0;

        & > nav {
            @include breakpoint($size-col-mobile-footer-break){
                width: 50%;
            }

            @include breakpoint($size-mobile-small){
                width: 100%;
                text-align: center;
            }
        }
    }
}