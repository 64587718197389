// ---- BREAKPOINTS -----
$size-desktop-small: 1084px;
$size-tablet: 900px;
$size-tablet-small: 855px;
$size-mobile: 600px;
$size-mobile-small: 350px;

// ---- WIDTHS -----
$size-max-content-width: 1385px;
$size-col-mobile-footer-break: 900px;
$size-col-mobile-small-footer-break: 650px;

// ---- TYPOGRAPHY -----
$base-font-size: 14px;

// ------ COLORS -------
//primary
$color-orange: #3e4956; // black - this is terrible too #f66200;
$color-blue: #b85a59; // light red - This is terrible #2395d6;
$color-white: #fff;
$color-grey-light: #f6fafc;
$color-grey: #eef3f6;
$color-grey-med: #ccd6dd;
$color-grey-dark: #6f828e;
$color-black: #3e4956;
$color-light-red: #b85a59;
$color-green: #58b467;
$color-red: #b83433;

//greys
$color-grey-2: #e2e9ed;
$color-grey-3: #d8e0e5;
$color-grey-med-2: #bfccd4;
$color-grey-med-3: #9daeb8;
$color-grey-dark-2: #596974;
$color-black-dark: #27303d;

//alerts
$color-alert-yellow: #f1dc8f;
$color-alert-red: #e8b1ad;

//gradients
$gradient-tilt: 90deg;
$gradient-tilt-header: 45deg;
$gradient-tilt-banner: 90deg;
$color-gradient-orange: #3e4956; //#f66200;
$color-gradient-red: #b83433;
$color-gradient-purple: #7863a9;

//shadows
$color-grid-box-hover-shadow: rgba(44, 44, 44, .25);
