@function image-url($image){
    @if variable-exists(imagePath) {
        @return url("#{$imagePath}#{$image}");
    }
    @return url("#{$image}");
}

//typography
@function rem($size) {
  $remSize: $size / $base-font-size;
  @return #{$remSize}rem;
}
