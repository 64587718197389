.side-by-side-compare {
    @extend %clearfix;

    h1 {
        margin: 0 0 8px 0;
    }

    a {
        margin-bottom: 6px;
        display: inline-block;
        font-size: rem(16px);
    }

    p {
        margin-bottom: 12px;
        font-weight: 600;
        color: $color-grey-dark;
    }

    & > div {
        float: left;
        &:first-child {
            margin-right: 10%;
            @include breakpoint($size-tablet){
                margin-right: 0;
                float: none;
            }
        }
    }

    ul {
        margin-bottom: 20px;
        color: $color-grey-dark;
        li {
            margin-bottom: 3px;
            font-size: rem(12px);

            &:before {
                content: "* ";
            }
        }
    }

    & + .btn {
        margin-bottom: 50px;
    }
}