$imagePath: "../images/";

@import "developer/styles";

.demo-specific__developer .demo-specific__colors {
  .orange { background-color: $color-orange; }
  .blue { background-color: $color-blue; }
  .white { background-color: $color-white; }
  .black { background-color: $color-black; }
  .green { background-color: $color-green; }
  .red { background-color: $color-red; }

  .grey-light { background-color: $color-grey-light; }
  .grey { background-color: $color-grey; }
  .grey-2 { background-color: $color-grey-2; }
  .grey-3 { background-color: $color-grey-3; }
  .grey-med { background-color: $color-grey-med; }
  .grey-med-2 { background-color: $color-grey-med-2; }
  .grey-med-3 { background-color: $color-grey-med-3; }
  .grey-dark { background-color: $color-grey-dark; }
  .grey-dark-2 { background-color: $color-grey-dark-2; }
  .black-dark { background-color: $color-black-dark; }
}