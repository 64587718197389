.reach-out {
    text-align: center;
    @extend %clearfix;

    section {
        width: 50%;
        float: left;

        @include breakpoint($size-tablet-small){
            width: auto;
            margin-bottom: 30px;
            float: none;
        }
    }

    &__social {
        a {
            height: 40px;
            width: 40px;
            margin-right: 30px;
            display: inline-block;

            &:last-child {
                margin: 0;
            }
        }
    }
}

.newsletter {
    margin: auto;
    max-width: 300px;
}

body.home .reach-out {
    margin-bottom: 90px;

    @include breakpoint($size-tablet-small){
        margin-bottom: 30px;
    }
}
