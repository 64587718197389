.code-snippet {
    margin-bottom: 35px;
    position: relative;

    &:hover {
        .btn {
            opacity: 1;
        }
    }

    textarea { 
        width:0;
        height:0;
        margin:0;
        padding:0;
        opacity:0;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .btn {
        width: 70px;
        margin: 0;
        padding: 2px 0;
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: rem(12px);
        text-align: center;
        opacity: 0.1;
        transition: opacity 0.5s;

        &.success {
            padding-right: 15px;
            &:after{
                width: 5px;
                height: 11px;
                content: '';
                display: block;
                border: solid $color-white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                position: absolute;
                right: 10px;
                top: 6px;
            }
        }
    }

    &__selector {
        background: #212a35;
        @extend %clearfix;

        nav {
            float: right;

            button {
                padding: 8px;
                text-transform: uppercase;
                color: $color-white;
                background: transparent;
                border: none;
                outline: none;
                font-size: rem(12px);
                font-weight: 600;

                @include breakpoint($size-mobile-small) {
                    padding: 6px;
                    font-size: rem(11px);
                }

                &.active {
                    padding-top: 5px;
                    border-top: 3px solid $color-blue;
                    background-color: #27303d;
                    color: $color-blue;
                }
            }
        }
    }

    &__cnt {
        overflow: hidden;
        pre {
            margin: 0 0 -30px;
            padding: 14px 0 75px 14px;
            border-radius: 0;
            clear: both;
            background-color: #27303d;
        }
    }
}